/* eslint-disable no-nested-ternary */
import React from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles, TextField } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import FormHelperText from '@material-ui/core/FormHelperText';
import {
  InstrumentNameCell,
  PriceColumn,
  useInstrumentNameCellStyles,
} from '../../../../../Modelportfolios/components/InstrumentsList/table-data';
import InputFormElement, { CurrencyNumberFormat } from '../../../../../RiskProfiling/components/StepContent/components/formElement/InputFormElement';
import RadioFormElement
  from '../../../../../RiskProfiling/components/StepContent/components/formElement/RadioFormElement';
import {
  ORDERING_AVAILABLE_OPTIONS_FIELD_NAMES,
  ORDERING_MP_AVAILABLE_OPTIONS_FIELD_NAMES,
  TRANSACTION_INSTR,
  TRANSACTION_INSTR_OPTIONS,
  TRANSACTION_INSTR_ORDERING_FIELD_NAMES,
  TRANSACTION_START_DATE,
  TRANSACTION_TYPE_INTEGER,
  TRANSACTION_TYPE_VALUES,
  TRANSACTION_START_DATE_OPTIONS,
  DATE_IN_PAST_ERROR,
} from '../../../../constants';
import CalendarFormElement
  from '../../../../../RiskProfiling/components/StepContent/components/formElement/CalendarFormElement';
import {
  formatQty, QTY_DECIMALS, valueOrDash, withEuroOrDash, withPercentOrDash,
} from '../../../../../../utils/utils';
import {
  getAmount,
  getAvailableOrderingFields,
  getInstrumentSri,
  getNumberValue,
  getTransactionItemSRRI,
  filterAvailableDiscounts, transactionValueToEuro, transactionValueToQuantity, percentageToQuantity, getAmountFraction,
} from '../../../../utils';
import DeleteIcon from '../../../../../../images/DeleteIcon';
import CheckboxFormElement
  from '../../../../../RiskProfiling/components/StepContent/components/formElement/CheckboxFormElement';
import { PercentageNumberFormat } from '../../../../../RiskProfiling/components/StepContent/components/formElement/InputFormElement/InputFormElement';
import SelectFormElement
  from '../../../../../RiskProfiling/components/StepContent/components/formElement/SelectFormElement';
import { PrimaryButton } from '../../../../../../components/Buttons';
import Link from '../../../../../../components/Link';
import {
  useRiskScoreConfirmationModalContext,
} from '../../../../../RiskProfiling/components/StepContent/components/step/ProductsSelectionSteps/components/InvestmentRecommendationStep/InvestmentRecommendationStep';
import { BaseNumberFormat } from '../../../../../../components/Inputs';
import {
  DATE_MUST_BE_IN_PAST,
  OPTION_IS_NOT_AVAILABLE_FOR_DEPOT_MSG,
  VALID_UNTIL_TODAY_VALUE,
} from '../../../../../RiskProfiling/constants';
import WarningTooltip, { InputWarningTooltip } from '../../../../../../components/WarningTooltip';
import { acceptanceCriteriasExists, getInstrName } from '../../../../../Modelportfolios/utils';
import { InfoIcon } from '../../../../../../images';
import { FilteringAcceptanceCriteriasModal } from '../../../../../Modelportfolios/components';
import {
  getInstrumentMinDate,
  getMaxBookingDate,
  MIN_DATE_ERROR_MSG,
} from '../../../../../VirtualPortfoliosDashboard/constants';
import {MODEL_PORTFOLIO_WEIGHT, MODEL_PORTFOLIO_WEIGHT_OPTIONS} from "../../../../../Modelportfolios/Create/constants";
import {SustainabilityCell} from "../../../../../../utils/commonTableColumns";
import Tooltip from "../../../../../../components/Tooltip";
import {getAssetInternalId} from "../../../../../CustomerDashboard/utils";

export const QtyNumberFormat = (props) => {
  return (
    <BaseNumberFormat
      {...props}
      decimalScale={props.decimalScale !== undefined ? props.decimalScale : QTY_DECIMALS}
      suffix={props.hasOwnProperty('suffix') ? props.suffix : " STÜCK"}
      placeholder={props.hasOwnProperty('placeholder') ? props.placeholder : "STÜCK"}
    />
  );
};

export const NoChangeError = ({item}) => {
  const error = _.get(item.errors, 'no_changes');

  return !!error ? <FormHelperText error>{error}</FormHelperText> : null;
};

const linearGradientColor = 'rgba(177, 177, 177, 0.13)'
const linearGradientSecond = 'rgba(250, 250, 250, 0.1)'

const useCellStyles = makeStyles((theme) => ({
  inputRoot: {
    height: 40,
    marginBottom: 0,
    background: 'white',
    '&.Mui-error': {
      borderColor: '#f44336',
    },

    '&.text-red': {
      color: '#f44336 !important'
    },
    '&.text-green': {
      color: '#60ce7a !important'
    },
    '&.linear-gradient': {
      color: 'rgba(0, 0, 0, 0.6)',
      background: `repeating-linear-gradient(-45deg, ${linearGradientSecond} 6px, ${linearGradientSecond} 10px, ${linearGradientColor} 0px, ${linearGradientColor} 17px)`
    }

  },
  helpText: {
    color: 'inherit',
  },
  radioWithInput: {
    '& .MuiFormControl-root .MuiFormGroup-root': {
      marginBottom: 10,
      marginTop: 0,
      height: 'auto',
    }
  },
  root: {
    marginBottom: 0,
    '& .MuiFormControlLabel-label': {
      fontSize: 'inherit',
      fontFamily: 'Roboto-Medium',
    },
    '& .MuiCheckbox-root': {
      padding: 8
    }
  },
  priceContainer: {
    textAlign: 'right',
    fontSize: 12,
    fontFamily: 'Roboto-Medium',
    '& div': {
      padding: '5px 0',
    }
  },
  labelRoot: {
    fontSize: 'inherit',
    fontWeight: 'bold'
  },
  radioGroupRoot: {
    height: 40,
    marginBottom: 0,
    marginTop: 0,
  },
  formControlLabelRoot: {
    marginLeft: -6,
    marginBottom: 0,
  },
  formControlLabelLabel: {
    fontSize: 'inherit',
    fontFamily: 'Roboto-Medium',
  },
  radioOption: {
    padding: '0 0.3rem'
  },

  radio: {
    fontSize: 12,
  },
  distributeButton: {
    height: 40,
  },
  amountWithDiscount: {
    color: '#0092E5',
  },
  actionCell: {
    display: 'inline-flex',
    verticalAlign: 'top',
    position: 'relative',
    zIndex: 0,
    width: '100%'
  },
  weightCell: {
    lineHeight: '40px',
  },
  subNavigationButton: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    padding: 5,

    '&.only-icon': {
      padding: 0,
      marginLeft: 10,

      '&:first-child': {
        marginLeft: 5
      },

      '& span:nth-child(2)': {
        marginRight: 0
      }
    },

    '&.error': {
      '& span:nth-child(2)': {
        color: 'rgb(244, 70, 56)',
      },
      '& span:first-child': {
        backgroundColor: 'rgba(244, 70, 56, 0.2)',
      },
    },

    '&:hover': {
      cursor: 'pointer'
    },

    '&:hover span:first-child': {
      width: '100%',
      height: '100%',
      borderRadius: 5
    },

    '& span:first-child': {
      transition: 'all',
      transitionDuration: 150,
      height: 28,
      width: 28,
      borderRadius: 20,
      backgroundColor: '#CCE9FA',
      position: 'absolute',
    },

    '& span:nth-child(2)': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 28,
      width: 28,
      borderRadius: 20,
      marginRight: 8,
      position: 'relative',
      zIndex: 99
    },

    '& span:last-child': {
      fontFamily: 'Roboto-Bold',
      fontSize: 14,
      color: theme.palette.primary.main,
      position: 'relative',
      zIndex: 99
    }
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
  disabledButton: {
    '& span:first-child': {
      backgroundColor: 'rgba(0, 0, 0, 0.12)'
    },
    '& span:last-child': {
      color: 'rgba(0, 0, 0, 0.26)',
    },
    '&:hover': {
      cursor: 'auto'
    },

    '&:hover span:first-child': {
      width: 28,
      height: 28,
      borderRadius: 20
    },
  }
}));

// NOTE: Component should return null if nothing to render
const NameCol = {
  header: [({options}) => InstrumentNameCell.header.content(options) || null],
  body: [({item, options}) => InstrumentNameCell.body.content(item.data, options) || null]
};

const StateCol = {
  header: [() => ''],
  body: [({item, options, ignoreBuy, hideDisabled=true}) => {
    if(!ignoreBuy && options.action === 'buy'){
      return null;
    }

    const classes = useCellStyles();

    const amountOnly = options.action === 'switch' || false;
    const quotationFieldsSource = options.isModelportfolio ?
      ORDERING_MP_AVAILABLE_OPTIONS_FIELD_NAMES : ORDERING_AVAILABLE_OPTIONS_FIELD_NAMES;

    const radioOptions = TRANSACTION_INSTR_OPTIONS.map((option) => {

      const isDisabled = !_.get(options, quotationFieldsSource[options.action], [])
        .includes(TRANSACTION_INSTR_ORDERING_FIELD_NAMES[option.id])

      return {...option, disabled: isDisabled || (amountOnly && option.id !== TRANSACTION_INSTR.amount)}
    })

    if (hideDisabled) {
      const availableOptions = _.filter(radioOptions, (option) => !option.disabled);
      if (availableOptions.length <= 1) {
        return null;
      }
    }

    return (
      <div className={classes.radio}>
        <RadioFormElement
          disabled={item.loading || item.sell_all || false}
          onChange={value => options.handleTransactionTypeChange && options.handleTransactionTypeChange(item, value)}
          value={item.transaction_type || ''}
          options={radioOptions}
          custom_classes={{
            labelRoot: classes.labelRoot, radioGroupRoot: classes.radioGroupRoot,
            formControlLabelRoot: classes.formControlLabelRoot, formControlLabelLabel: classes.formControlLabelLabel,
            radioOption: classes.radioOption
        }}
          error={_.get(item, 'errors.transaction_type')}
        />
      </div>
    )
  }]
};

const SwitchInNameCol = {
  header: [() => 'Zielfonds'],
  body: [({item, options}) => InstrumentNameCell.body.content(item.data, options) || null]
};

const isNewVirtualAmountDisabled = (options) => {
  return options.isNewVirtual && options.transactionsWeightType != MODEL_PORTFOLIO_WEIGHT.AUTO;
}

const AmountColumn = {
  header: [({options}) => {
    return options.action === "buy" ? options.isVirtual ? 'Bruttobetrag' : <span>Einmalanlage<br/>Betrag</span> : "Betrag"
  }],
  body: [({item, options}) => {
    const classes = useCellStyles();

    let transactionValueError = _.get(item, 'errors.transaction_value');
    let assetPriceError = _.get(item, 'errors.asset_price');
    const error = <>
      {!!transactionValueError && !!assetPriceError ? (
        <>{transactionValueError} <br /> {assetPriceError}</>
      ) : (
        <>{transactionValueError || assetPriceError}</>
      )}
    </>;

    let isAmount = item.transaction_type === TRANSACTION_INSTR.amount;

    let inputComponent = isAmount ? CurrencyNumberFormat : QtyNumberFormat;
    if (options.action === 'switch' && options.sellAll){
      inputComponent = PercentageNumberFormat;
    }
    const inputProps = {allowNegative: false, fixedDecimalScale: isAmount};
    let decimals;
    if(inputComponent === QtyNumberFormat){
      decimals = false;
      inputProps.decimalScale = options.maxQtyDecimals;

      if(!item.data.is_fund) {
        // decimals are not allowed for Qty and not fund items
        inputProps.decimalSeparator = false;
      }
    }

    const ignoreBuy = true;

    return (
      <>
        {options.action === "buy" && (
          <div className={classes.radioWithInput}>
            {StateCol.body[0]({item, options, ignoreBuy})}
          </div>
        )}
        <InputFormElement
          disabled={item.loading || item.sell_all || isNewVirtualAmountDisabled(options) || false}
          error={(transactionValueError || assetPriceError) ? error : options.hasError}
          value={getNumberValue(item.transaction_value, decimals)}
          onChange={value => options.handleTransactionValueChange && options.handleTransactionValueChange(item, value)}
          inputComponent={inputComponent}
          inputProps={{inputProps: inputProps}}
          type={'text'}
          custom_classes={classes}
        />
        {!options.isModelportfolio && (!isAmount || options.isVirtual) && (
          <div className={classes.priceContainer}>
            {item.loading ? (
                <Skeleton />
              ) : (
                <>
                  <div>Preis: {withEuroOrDash(item.data.price_eur)}</div>
                  {!isAmount && <div>Gesamtbetrag: {withEuroOrDash(getAmount(item))}</div> }
                </>
            )}
          </div>
        )}
      </>
    )
  }]
};

// region SWITCH AMOUNT IN QUANTITY CONFIGURATION

export function _SwitchQuantityFieldWrapper(component, valueCallback, onChangeCallback, errorCallback, InputProps, customClassesCallback=undefined, disabled=false) {

  return function ({item, options}) {

    const classes = useCellStyles();
    const customClasses = customClassesCallback && customClassesCallback(item, options)

    const handleValueChange = React.useCallback((value) => {
      onChangeCallback(item, options) && onChangeCallback(item, options)(item, value)
    }, [item, options])

    let isAmount = item && item.transaction_type === TRANSACTION_INSTR.amount;

    const inputProps = {
      allowNegative: false,
      fixedDecimalScale: isAmount,
      ...(InputProps || {})
    };

    let decimals;
    if(component === QtyNumberFormat){
      decimals = false;
      inputProps.decimalScale = options.maxQtyDecimals;

      if(item && !item.data.is_fund) {
        // decimals are not allowed for Qty and not fund items
        inputProps.decimalSeparator = false;
      }
    }

    const ignoreBuy = true;

    return (
      <>
        <InputFormElement
          disabled={(item && item.sell_all) || (item && item.action === 'delete') || disabled || false}
          error={errorCallback(item, options)}
          value={getNumberValue(valueCallback(item, options), decimals)}
          onChange={handleValueChange}
          inputComponent={component}
          inputProps={{inputProps: inputProps}}
          type={'text'}
          custom_classes={{
            ...classes,
            inputRoot: clsx(classes.inputRoot, _.get(customClasses, 'inputRoot'))
          }}
        />

        {component != PercentageNumberFormat && item && item.hasOwnProperty('buy') && (
          <div className={classes.priceContainer}>
            <div>Aktuell: {component == CurrencyNumberFormat ? withEuroOrDash(item.data.market_value) : `${formatQty(item.data.quantity, options.maxQtyDecimals)}`}</div>
          </div>
        )}
      </>
    )

  }
}

const _SwitchSellAllCheckWrapper = (item, options, onChangeCallback, valueToQuantity) => (instr, value) => {
  const [sellAllAvailable, qtyAvailable] = getSellAllAndQtyAvailable(item, options);
  // switch has transaction type QTY so we need to convert value to qty
  const valueToCheck = _.isFunction(valueToQuantity) ? valueToQuantity(value, item, false) : value;
  if (!checkSellAllExceed(item, options, sellAllAvailable, valueToCheck, 'quantity')){
    onChangeCallback(instr, value)
  }
};

export const SwitchOutAmountQuantityColumn = {
  header: [() => (<>Anteile<br/>Tausch</>)],
  body: [_SwitchQuantityFieldWrapper(
    QtyNumberFormat,
    (item) => item.transaction_value,
    (item, options) => _SwitchSellAllCheckWrapper(item, options, options.handleTransactionValueChange),
    (item) => _.get(item.errors, 'transaction_value'),
    {suffix: ''})],
  cellClassName: 'switch-quantity-cell'
}

export const SwitchInAmountQuantityColumn = {
  body: [_SwitchQuantityFieldWrapper(
    QtyNumberFormat,
    (item) => item.transaction_value,
    (_, options) => (item, value) => options.handleTransactionValueChange && options.handleTransactionValueChange(item, value, options.switchOutItem),
    (item) => _.get(item.errors, 'transaction_value'),
    {suffix: ''})],
  header: [_SwitchQuantityFieldWrapper(
    QtyNumberFormat,
    (item, options) => options.transactionSaldo,
    () => null,
    () => null,
    {suffix: '', placeholder: '', fixedDecimalScale: true, allowNegative: true},
    (item, options) => ({inputRoot: options.transactionSaldo != 0 ? 'text-red' : 'text-green'}),
    true)],
  cellClassName: 'switch-quantity-cell'
}

const SwitchInSaldoPlaceholderColumn = {
  header: [() => 'Saldo'],
  body: [() => null],
  cellClassName: 'saldo-placeholder'
}
// endregion

// region SWITCH AMOUNT IN EURO CONFIGURATION
export const SwitchOutAmountEuroColumn = {
  header: [() => <>Betrag<br/>Tausch</>],
  body: [_SwitchQuantityFieldWrapper(
    CurrencyNumberFormat,
    (item) => _.get(item, 'calculated.transaction_value_euro'),
    (item, options) => _SwitchSellAllCheckWrapper(
      item, options, options.handleSwitchAmountEuroChanged, transactionValueToQuantity),
    (item) => _.get(item.errors, 'transaction_value')),
    NoChangeError
  ],
  cellClassName: 'switch-amount-cell'
}

export const SwitchInAmountEuroColumn = {
  header: [({options}) => {

    const classes = useCellStyles();

    const handleDistributeQuantitiesClick = () => {
      options.onDistributeQuantitiesClick && options.onDistributeQuantitiesClick()
    }

    return (
        <PrimaryButton
          text="Verteilen"
          customClasses={{root: classes.distributeButton}}
          disabled={options.transactionSaldo == 0}
          onButtonClick={handleDistributeQuantitiesClick}
        />
    )
  }],
  body: [_SwitchQuantityFieldWrapper(
    CurrencyNumberFormat,
    (item) => _.get(item, 'calculated.transaction_value_euro'),
    (_, options) => (item, value) => options.handleSwitchAmountEuroChanged && options.handleSwitchAmountEuroChanged(item, value, options.switchOutItem),
    (item) => _.get(item.errors, 'transaction_value_euro'))],
  cellClassName: 'switch-amount-cell'
}
// endregion

// region SWITCH AMOUNT IN PERCENTAGE CONFIGURATION
export const SwitchOutAmountPercentageColumn = {
  header: [() => (<>Prozent<br/>Tausch</>)],
  body: [_SwitchQuantityFieldWrapper(
    PercentageNumberFormat,
    (item) => _.get(item, 'calculated.transaction_value_percentage'),
    (item, options) => _SwitchSellAllCheckWrapper(
      item, options, options.handleSwitchPercentageChanged, percentageToQuantity),
    (item) => _.get(item.errors, ''))],
  cellClassName: 'switch-percentage-cell'
}

export const SwitchInAmountPercentageColumn = {
  header: [() => ''],
  body: [_SwitchQuantityFieldWrapper(
    PercentageNumberFormat,
    (item) => _.get(item, 'calculated.transaction_value_percentage'),
    (_, options) => (item, value) => options.handleSwitchPercentageChanged && options.handleSwitchPercentageChanged(item, value, options.switchOutItem),
    (item) => _.get(item.errors, 'transaction_value_percentage'))],
  cellClassName: 'switch-percentage-cell'
}
// endregion

const isNewVirtualWeightDisabled = (options) => {
  return options.isNewVirtual && options.transactionsWeightType != MODEL_PORTFOLIO_WEIGHT.MANUAL;
}

const useNewMPWeightColumnStyles = makeStyles(() => ({
  inputRoot: {marginBottom: 0, height: 30},
  controlContainer: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    '& > i': {
      position: 'absolute',
      right: -25
    }
  }
}))

const MP_WEIGHT_EXPLANATION_TEXT = {
  [MODEL_PORTFOLIO_WEIGHT.AUTO]: 'Die Gewichtung berechnet sich automatisch anhand der im Feld “Bruttobetrag” eingegebenen Werte und entspricht einer Gewichtung von insgesamt 100%.',
  [MODEL_PORTFOLIO_WEIGHT.MANUAL]: 'Geben Sie hier die gewünschte prozentuale Verteilung an. Die Gewichtung muss in Summe 100% betragen und es wird ein Wert für das Feld “Musterdepotwert” benötigt.',
  [MODEL_PORTFOLIO_WEIGHT.EQUAL]: 'Jedes Produkt im Portfolio erhält prozentual die selbe Gewichtung.',
  [MODEL_PORTFOLIO_WEIGHT.RISK_BASED]: 'Bei risikoparitätischen Ansätzen wird das Vermögen auf verschiedene, nicht miteinander korrelierende Asset-Klassen unter Risikogesichtspunkten verteilt.'
};

export const NewMPWeightColumn = {
  header: [({options}) => {

    const classes = useNewMPWeightColumnStyles();

    const tooltip = MP_WEIGHT_EXPLANATION_TEXT[options.transactionsWeightType];

    return (
      <>
        <span>Gewichtung</span>
        <div className={classes.controlContainer}>
          <SelectFormElement
            options={MODEL_PORTFOLIO_WEIGHT_OPTIONS}
            value={options.transactionsWeightType || ''}
            onChange={options.handleTransactionWeightTypeChanged}
            custom_classes={{inputRoot: classes.inputRoot}}
            disabled={options.isWeightLoading}
          />
          {tooltip && (
            <WarningTooltip title={tooltip} />
          )}
        </div>
      </>
    )
  }],
  body: [({item, options}) => {

    if (options.isWeightLoading) {
      return <Skeleton  style={{width: '100%'}}/>
    }

    return _SwitchQuantityFieldWrapper(
      PercentageNumberFormat,
      (item) => _.get(item, 'calculated.transaction_value_weight'),
      (_, options) => (item, value) => options.handleTransactionWeightChanged && options.handleTransactionWeightChanged(item, value),
      (item) => _.get(item.errors, 'transaction_value_weight'), {}, undefined,
      isNewVirtualWeightDisabled(options))({item, options})
  }]
}

const getSellAllAndQtyAvailable = (item, options) => {
  const availableOrderingFields = getAvailableOrderingFields(options, options.action);
  const sellAllAvailable = availableOrderingFields.includes(TRANSACTION_INSTR_ORDERING_FIELD_NAMES.is_all);
  const qtyAvailable = availableOrderingFields.includes(TRANSACTION_INSTR_ORDERING_FIELD_NAMES[TRANSACTION_INSTR.qty]);

  return [sellAllAvailable, qtyAvailable]
};

const checkSellAllExceed = (item, options, sellAllAvailable, value, fieldToCompare='market_value') => {
  if (options.handleSellAllChanged && !item.sell_all) {
    let availableValue = item.data[fieldToCompare];
    let sell_all = !!availableValue;

    if (availableValue) {
      // CIOS rounds market value - so we should round to same fraction
      const fraction = getAmountFraction(availableValue);

      value = _.round(value, fraction);
      const existingSell = options.existingSellTransaction || (options.existingSwitchOuts && options.existingSwitchOuts[getAssetInternalId(item.data)]);
      if (existingSell) {
        const existingSellValueGetter = fieldToCompare === 'market_value' ? transactionValueToEuro : transactionValueToQuantity;
        availableValue -= existingSellValueGetter(existingSell.transaction_value, existingSell);
        availableValue = _.round(availableValue, fraction);
        sell_all = false;
      }
    }

    availableValue = Math.max(availableValue, 0); // availableValue should not be negative

    // in case it's not sell_all value should be eq to availableValue
    if (sell_all ? availableValue <= value : availableValue < value) {
      options.handleSellAllChanged(item, sell_all, sellAllAvailable, availableValue);

      return true;
    }
  }

  return false;

};

const checkSellAll = (item, options, sellAllAvailable, fieldToCheck='transaction_value', fieldToCompare='market_value') => {
  return checkSellAllExceed(item, options, sellAllAvailable, item[fieldToCheck] || 0, fieldToCompare);
};

const SelectAllColumn = {
  header: [() => ''],

  body: [({item, options}) => {
    const classes = useCellStyles();

    const [sellAllAvailable, qtyAvailable] = getSellAllAndQtyAvailable(item, options);

    checkSellAll(item, options, sellAllAvailable, 'transaction_value',
      item.transaction_type === TRANSACTION_INSTR.amount ? 'market_value' : 'quantity');

    return(
      <CheckboxFormElement
        label={options.action === 'switch' ? "Alles tauschen" : "Alles verkaufen"}
        value={item.sell_all || false}
        onChange={value => options.handleSellAllChanged && options.handleSellAllChanged(item, value, sellAllAvailable)}
        custom_classes={classes}
        error={_.get(item, 'errors.sell_all')}
        disabled={!item.sell_all && !sellAllAvailable && !qtyAvailable}
      />
    )
  }]
}

const DateColumn = {
  header: [({options}) => {
    return options.isVirtual
      ? <>
        Buchungsdatum
        <WarningTooltip
          title={
            options.action === TRANSACTION_TYPE_VALUES.BUY
              ? "Das vorausgewählte Datum zeigt Ihnen den aktuellsten verfügbaren Preis an. " +
                "Sie können das Buchungsdatum in dem Zeitraum zwischen diesem Datum und dem " +
                "Auflegungsdatum des jeweiligen Produktes wählen."
              : "Das vorausgewählte Datum zeigt Ihnen den aktuellsten verfügbaren Preis an. " +
                "Bei Verkauf bzw. Tausch muss das Buchungsdatum nach dem Datum des erstmaligen Erwerbes des zu " +
                "veräußernden Produktes liegen."
          }
        />
      </>
      : options.action === TRANSACTION_TYPE_VALUES.BUY ? 'Ausführung' : '';
  }],
  body: [({item, options}) => {
    const classes = useCellStyles();

    let minDepositDate, maxDepositDate, minErrorMsg, maxErrorMsg;
    if(options.isVirtual){
      // for virtual portfolio trading happens in past -> max day is today
      minDepositDate = getInstrumentMinDate(item, options.action);
      minErrorMsg = MIN_DATE_ERROR_MSG;
      maxDepositDate = getMaxBookingDate();
      maxErrorMsg = DATE_MUST_BE_IN_PAST;
    } else {
      minDepositDate = options.minDepositDate;
      minErrorMsg = DATE_IN_PAST_ERROR;
      maxDepositDate = undefined
    }

    return (
      <>
        {/* Virtual portfolio order happens in past -> there can't be TRANSACTION_START_DATE.rightAway */}
        {!options.isVirtual &&
          <div className={classes.radioWithInput}>
            <div className={classes.radio}>
              <RadioFormElement
                onChange={value => options.handleDepositDateTypeChange && options.handleDepositDateTypeChange(item, value)}
                value={item.deposit_from_type || ''}
                options={TRANSACTION_START_DATE_OPTIONS}
                custom_classes={{
                  labelRoot: classes.labelRoot, radioGroupRoot: classes.radioGroupRoot,
                  formControlLabelRoot: classes.formControlLabelRoot, formControlLabelLabel: classes.formControlLabelLabel,
                  radioOption: classes.radioOption
                }}
              />
            </div>
          </div>
        }

        <CalendarFormElement
          disabled={item.deposit_from_type === TRANSACTION_START_DATE.rightAway}
          error={item.errors && item.errors.deposit_from_date}
          onChange={value => options.handleDepositDateChange && options.handleDepositDateChange(item, value, options.action)}
          value={item.deposit_from_date || null}
          custom_classes={classes}
          minDate={minDepositDate}
          minError={minErrorMsg}
          maxDate={maxDepositDate}
          maxError={maxErrorMsg}
          disableWeekends
        />

        {!options.isVirtual &&
          <FormHelperText classes={{root: classes.helpText}}>
            Die Order wird zum nächstmöglichen Termin ausgeführt.
          </FormHelperText>
        }
      </>
    )

  }]
};

const ItemWeightColumn = {
  header: [({options}) => 'Aktueller Wert'],
  body: [({item, options}) => {
    const classes = useCellStyles();

    let isAmount = item.transaction_type === TRANSACTION_INSTR.amount;

    return (
      <div className={classes.actionCell}>
        <span className={classes.weightCell}>
          {isAmount ? withEuroOrDash(item.data.market_value) : `${formatQty(item.data.quantity, options.maxQtyDecimals)} STÜCK`}
        </span>
      </div>
    );
  }],
  align: 'right'
};

export const BrokerFeeColumn = {
  key: 'BrokerFeeColumn',
  header: [({options}) => { return options.action === 'sell'
    ? ItemWeightColumn.header[0]({options})
    : options.isModelportfolio ? <span>Einstiegs-<br/>entgelt</span> : <span>Ausgabe-<br/>aufschlag</span> }],
  body: [({item, options}) => {
    if(options.action === 'sell'){
      return ItemWeightColumn.body[0]({item, options});
    }

    const classes = useCellStyles();

    const discount = (item.discount || 0) / 100;

    // brokerFee is used to represent fee like 50.5; brokerFeeAsDecimal is ude to represent fee like 0.505
    let brokerFee = options.isVirtual
      ? item.data['product_fees'] ? _.get(item.data.product_fees, 'entry_cost', 0) : 0
      : item.broker_fee || 0;

    let brokerFeeAsDecimal = brokerFee / 100;

    const brokerFeeInEur = getAmount(item, null, options.switchOutItem) * brokerFeeAsDecimal;

    return (
      <span className={classes.nowrap}>
        <span>{withPercentOrDash(brokerFee, false)}</span><br />
        <span className={discount > 0 ? classes.amountWithDiscount : ''}>
          {withEuroOrDash(brokerFeeInEur - brokerFeeInEur * discount)}
        </span>
      </span>
    )
  }],
  align: 'right'
};

const EntryFeeColumn = {
  header: [() => 'Anmerkung'],
  body: [({item, options}) => {
    const classes = useCellStyles();
    const error = item.errors && item.errors.entry_fee;

    return (
      <InputFormElement
        error={error}
        value={getNumberValue(item.entry_fee)}
        inputComponent={PercentageNumberFormat}
        onChange={value => options.handleEntryFeeChange && options.handleEntryFeeChange(item, value)}
        type={'text'}
        custom_classes={classes}
      />
    )
  }],
};

const OngoingFeeColumn = {
  header: [() => (<span>Anmerkung<br/>Laufendes Entgelt</span>)],
  body: [({item, options}) => {
    const classes = useCellStyles();
    const error = item.errors && item.errors.ongoing_fee;

    return (
      <InputFormElement
        error={error}
        value={getNumberValue(item.ongoing_fee)}
        inputComponent={PercentageNumberFormat}
        onChange={value => options.handleOngoingFeeChange && options.handleOngoingFeeChange(item, value)}
        type={'text'}
        custom_classes={classes}
      />
    )
  }],
};

export const VirtualDiscountInput = ({item, options, classes}) => (
  <InputFormElement
    disabled={!_.get(item, 'data.product_fees.entry_cost')}
    error={item.errors && item.errors.discount}
    value={getNumberValue(item.discount)}
    onChange={(value) => {
      // if value is removed - leave empty cell, otherwise update discount if it is in range [0-100]
      const val = _.isNil(value) ? value : _.inRange(value, 0, 101) ? value : item.discount;
      options.handleDiscountChange(item, val);
    }}
    inputComponent={PercentageNumberFormat}
    inputProps={{ inputProps: { allowNegative: false }}}
    type="text"
    custom_classes={classes}
    customStyle={{ width: '25%', minWidth: 71 }}
  />
);

const DiscountColumn = {
  header: [({ options }) => (
    options.action === 'sell' ? '' : options.isVirtual ? 'Rabatt' : 'Anmerkung'
  )],
  body: [({ item, options }) => {
    if (options.action === 'sell') {
      return null;
    }

    const classes = useCellStyles();

    if (options.isVirtual) {
      return <VirtualDiscountInput item={item} options={options} classes={classes} />;
    }
    const maxDiscount = item.max_discount || 0;

    const availableDiscounts = filterAvailableDiscounts(options.availableDiscounts, maxDiscount);

    return (
      <>
        <SelectFormElement
          error={item.errors && item.errors.discount}
          value={item.discount || ''}
          options={availableDiscounts}
          onChange={(value) => options.handleDiscountChange && options.handleDiscountChange(item, value)}
          custom_classes={classes}
          disabled={options.discountsDisabled}
          style={{ display: 'inline-block', width: options.discountsDisabled ? 'calc(100% - 12px)' : '100%' }}
        />
        {options.discountsDisabled && (
          <InputWarningTooltip title={OPTION_IS_NOT_AVAILABLE_FOR_DEPOT_MSG} />
        )}
      </>
    )
  }],
};

const CourseColumn = {
  header: [() => 'Kurs (EUR)'],
  body: [({item, options}) => {
    return (
      valueOrDash(item.data && item.data.calculated && item.data.calculated.last_price_value)
    )
  }]
}

const OrderVariationColumn = {
  header: [() => 'Orderzusatz'],
  body: [({item, options}) => {
    const classes = useCellStyles();
    let orderVariations = [{id: 'no_order', value: 'no order', label: 'Kein Orderzusatz'}];
    if (options.orderTypeOptions) {
      orderVariations = [...orderVariations, ...(options.orderTypeOptions || [])]
    }
    if(typeof item.order_type === 'undefined'){
      options.handleOrderVariationChange && options.handleOrderVariationChange(item, orderVariations[0].value)
    }

    return (
      <SelectFormElement
        error={item.errors && item.errors.order_type}
        value={item.order_type}
        options={orderVariations}
        onChange={value => options.handleOrderVariationChange && options.handleOrderVariationChange(item, value)}
        custom_classes={classes}
      />
    )
  }]
};

export const calculateTotalAmount = (item, limit, quantity) => {
  if (limit) {
    return limit * quantity || 0
  } else {

    const calculated = (item.hasOwnProperty('data')
      ? _.get(item, 'data.calculated')
      : item.calculated) || {};

    if (!_.isEmpty(calculated)) {
      return calculated.last_price_value * quantity || 0;
    }
  }

  return 0
};

const LimitOrderColumn = {
  header: [() => 'Limit (EUR)'],
  body: [({item, options}) => {
    const classes = useCellStyles();

    const error = item.errors && item.errors.limit;
    let placeholder;
    if (item.order_type === 'no order') {
      placeholder = 'billigst'
      if (options.action === 'sell') {
        placeholder = 'bestens'
      }
    }
    const inputProps = {allowNegative: false, placeholder: placeholder};

    return (
      <>
        <InputFormElement
          error={error || options.hasError}
          value={getNumberValue(item.limit)}
          onChange={value => options.handleLimitValueChange && options.handleLimitValueChange(item, value, calculateTotalAmount(item, value, item.quantity))}
          inputComponent={CurrencyNumberFormat}
          inputProps={{inputProps: inputProps}}
          type={'text'}
          custom_classes={classes}
        />
      </>
    )
  }]
};

const NumberOfSharesColumn = {
  header: [() => 'Stückzahl'],
  body: [({item, options}) => {
    const classes = useCellStyles();

    const error = item.errors && item.errors.quantity;
    const inputProps = {allowNegative: false, suffix: '', placeholder: '', decimalScale: 0};

    return (
      <>
        <InputFormElement
          error={error || options.hasError}
          value={item.quantity}
          disabled={item.sell_all}
          onChange={value => options.handleQuantityChange && options.handleQuantityChange(item, value, options.action)}
          inputComponent={QtyNumberFormat}
          inputProps={{inputProps: inputProps}}
          type={'text'}
          custom_classes={classes}
        />
      </>
    )
  }]
};

const OrderAmountColumn = {
  header: [() => 'Betrag'],
  body: [({item, options}) => {
    const classes = useCellStyles();
    const inputProps = {allowNegative: false};

    return (
      <>
        <InputFormElement
          disabled
          value={getNumberValue(item.transaction_value)}
          inputComponent={CurrencyNumberFormat}
          inputProps={{inputProps: inputProps}}
          type={'text'}
          custom_classes={classes}
        />
      </>
    )
  }],
  footer: [({options}) => {
    const {totalAmount} = options;

    return (
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <div>
          <b>
            {options.error && (<span style={{color: 'red'}}>{options.error}</span>)}
          </b>
        </div>
        <div style={{textAlign: 'right'}}>
          <span style={{fontWeight: 'bold'}}>Insgesamt</span>
          <span style={{fontWeight: 'bold', color: '#23D374', marginLeft: 12}}>{withEuroOrDash(totalAmount)}</span>
        </div>
      </div>
    )
  }],
  colSpan: {footer: 6},
  align: 'right'
};

const SellEverythingColumn = {
  header: [() => 'Alles verkaufen'],
  body: [({item, options}) => {
    const classes = useCellStyles();

    const [sellAllAvailable, qtyAvailable] = getSellAllAndQtyAvailable(item, options);

    checkSellAll(item, options, sellAllAvailable, 'quantity', 'quantity');

    return (
      <CheckboxFormElement
        label={"Alles verkaufen"}
        value={item.sell_all || false}
        onChange={value => options.handleSellAllChanged && options.handleSellAllChanged(item, value, sellAllAvailable)}
        custom_classes={classes}
        error={_.get(item, 'errors.sell_all')}
        disabled={!item.sell_all && !sellAllAvailable && !qtyAvailable}
      />
    );
  }]
};

const OrderValidityDateColumn = {
  header: [() => 'Gültigkeit'],
  body: [({item, options}) => {
    const classes = useCellStyles();
    const currentDate  = moment();

    const currentMonthOption = {
      value: moment(currentDate).endOf('month').format('YYYY-MM'), label: `Ultimo ${currentDate.format('MMMM')}`
    }
    let orderValidityDates = [{value: VALID_UNTIL_TODAY_VALUE, label: 'tagesgültig'}, currentMonthOption];
    let futureMonth = moment(currentDate).add(1, 'M');
    for (let m = 1; m < 12; m++) {
      orderValidityDates.push({
        value: moment(futureMonth).endOf('month').format('YYYY-MM'), label: `Ultimo ${futureMonth.format('MMMM')}`
      })
      futureMonth = moment(futureMonth).add(1, 'M');
    }

    if(typeof item.valid_until === 'undefined'){
      options.handleValidUntilChange && options.handleValidUntilChange(item, currentMonthOption.value, currentMonthOption)
    }

    const onChange = (value) => {
      const option = _.find(orderValidityDates, (o) => o.value == value)
      options.handleValidUntilChange && options.handleValidUntilChange(item, value, option)
    }

    return (
      <SelectFormElement
        error={item.errors && item.errors.valid_until}
        value={item.valid_until}
        options={orderValidityDates}
        onChange={onChange}
        custom_classes={classes}
      />
    )
  }]
};

const StockExchangeColumn = {
  header: [() => 'Börsenplatz'],
  body: [({item, options}) => {
    const classes = useCellStyles();
    let stockExchangeOptions = options.stockExchangeOptions || [];
    if(typeof item.stock_exchange === 'undefined'){
      const option = stockExchangeOptions[0] || {};
      options.handleStockExchangeChange && options.handleStockExchangeChange(item, option.value || null, option)
    }

    const onChange = (value) => {
      const option = _.find(stockExchangeOptions, (o) => o.value == value)
      options.handleStockExchangeChange && options.handleStockExchangeChange(item, value, option)
    }

    return (
      <SelectFormElement
        error={item.errors && item.errors.stock_exchange}
        value={item.stock_exchange}
        options={stockExchangeOptions}
        onChange={onChange}
        custom_classes={classes}
      />
    )
  }]
};

const ActionsColumn = {
  header: [() => ''],
  body: [({item, options}) => {
    const classes = useCellStyles();

    let itemSRRI = getTransactionItemSRRI(item, options.isModelportfolio, true)

    const displayTextFieldButton = ![TRANSACTION_TYPE_INTEGER.SELL, TRANSACTION_TYPE_INTEGER.SWITCH].includes(item.tradingType) && options.clientSRRI && itemSRRI && itemSRRI > options.clientSRRI

    const { onSelectAsset } = useRiskScoreConfirmationModalContext()

    return (
      <div className={classes.actionCell}>
        {options.handleRemoveClick && (
          <Tooltip title="Löschen" placement="top">
            <span
              className={clsx(classes.subNavigationButton, 'only-icon', options.isWeightLoading && classes.disabledButton)}
              onClick={() => !options.isWeightLoading && options.handleRemoveClick && options.handleRemoveClick(options.action, item)}
            >
              <span />
              <span><DeleteIcon color={options.isWeightLoading && 'rgba(0, 0, 0, 0.26)'}/></span>
            </span>
          </Tooltip>

        )}

        {displayTextFieldButton && (
          <span className={clsx(classes.subNavigationButton, 'only-icon', _.get(item, 'errors.risk_score_explanation') && 'error')}
                onClick={() => onSelectAsset && onSelectAsset(item)}
          >
            <span />
            <span>§</span>
          </span>
        )}
      </div>
    )
  }],
};

const ShortActionsColumn = {
  header: [() => ''],
  body: [({item, options}) => {
    if(options.readOnly && !options.clientSRRI){
      return null;
    }

    // We do not validate instruments for open only, so we can skip disabledByFilter validation
    if (options.isOpenOnly) {

      if (item.infoText) {
        return (<WarningTooltip title={item.infoText} icon={<i><InfoIcon color="#80858c" /></i>}/>)
      }

      return null
    }

    const classes = useCellStyles();

    const itemSRI = getInstrumentSri(item);

    const displayTextFieldButton = !options.isOpenOnly && options.clientSRRI && itemSRI && itemSRI > options.clientSRRI

    const { onSelectAsset } = useRiskScoreConfirmationModalContext()

    return (
      <>
        {displayTextFieldButton && (
          <span className={classes.actionLink} style={{margin: 0}}>
            <Link style={!!_.get(item, 'error.risk_score_explanation') ? {color: "red"} : {}} title="Begründung" fontWeight="normal"
                  onClick={() => onSelectAsset && onSelectAsset(item)}/>
          </span>
        )}
        {!options.readOnly && !options.isOpenOnly && (
          <span className={classes.actionLink} style={{margin: 0}}>
            <Link title="Löschen" fontWeight="normal"
                  onClick={() => options.handleRemove && options.handleRemove(item)}/>
          </span>
        )}
      </>
    )
  }]
};

const tableStructure = [
  NameCol,
  StateCol,
  AmountColumn,
  DateColumn,
  BrokerFeeColumn,
  DiscountColumn,
  ActionsColumn
];

export const getPortfolioLink = (portfolio) => {
  return _.get(portfolio, 'factsheet_link')
};

export const openPortfolioLink = (portfolio) => {
  const link = getPortfolioLink(portfolio);
  if (link) {
    window.open(link, '_blank')
  }
};

export const PortfolioNameCell = ({portfolio}) => {
  const classes = useInstrumentNameCellStyles();

  const name = _.get(portfolio, 'name');
  const mpNumber = _.get(portfolio, 'mp_number');
  const link = getPortfolioLink(portfolio);

  const handleLinkClick = () => {
    openPortfolioLink(portfolio)
  };

  return (
    <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
      <div style={{minWidth: 0}}>
        <p className={classes.title} style={{margin: 0, padding: 0}} title={name}>
          <Link title={name} onClick={handleLinkClick} customClasses={!link && {link: classes.linkDisabled}} />
        </p>
        {!_.isNil(mpNumber) && <p style={{margin: 0}}> {mpNumber} </p>}
      </div>
    </div>
  )
};

export const PortfolioNameColumn = {
  header: [() => {
    return (
      <div>
        <span>Portfolio Name</span><br />
        <span style={{fontWeight: 'normal'}}>MP Nummer</span>
      </div>
    )
  }],
  body: [({item}) => <PortfolioNameCell portfolio={item.data}/>]
}

const InternalNameCol = {
  header: [({item, options}) => InstrumentNameCell.header.content(item, options) || null],
  body: [({item, options}) => InstrumentNameCell.body.content(item, options) || null],
}

const PriceCol = {
  header: [({item, options}) => PriceColumn.header.content(item, options) || null],
  body: [({item, options}) => PriceColumn.body.content(item, options) || null],
  align: 'right'
}

// TODO: Need to remove this duplicate. Duplicated due to internal problem with importing
const StatusCol = {
  header: [() => ''],
  body: [({item, options}) => {
    if(options.readOnly){
      return null;
    }

    // We do not validate instruments for open only, so we can skip disabledByFilter validation
    if (options.isOpenOnly) {
      return null
    }

    const classes = useCellStyles();

    const [acceptanceVisible, setAcceptanceVisibility] = React.useState(false);

    const handleShowAcceptanceCriteriasClick = () => {
      setAcceptanceVisibility(true)
    };

    const handleCloseAcceptanceCriterias = () => {
      setAcceptanceVisibility(false)
    };

    if (!item.disabledByFilter) return (
      <>
        {!item.dirty && <span className={classes.disabledLabel}>NEU</span>}
      </>
    );

    if (acceptanceCriteriasExists(item)) return (
      <>
        <InfoIcon color="#80858c" onClick={handleShowAcceptanceCriteriasClick}/>
        <FilteringAcceptanceCriteriasModal
          open={acceptanceVisible}
          onClose={handleCloseAcceptanceCriterias}
          instrumentName={getInstrName(item) || item.isin}
          criterias={item.disabledByAcceptanceCriterias} />
      </>
    );
  }]
}

const useTextFieldWithoutStyles = makeStyles({
  underline: {
    "&&&:before": {
      borderBottom: "none"
    },
    "&&:after": {
      borderBottom: "none"
    }
  }
});

export const PriceEurCol = {
  header: [() => 'Preis'],
  body: [({item, options}) => {
    const classes = useTextFieldWithoutStyles();
    const error = _.get(item, 'errors.asset_price');
    if (_.get(item, 'loading')) {
      return <Skeleton  style={{width: '100%'}}/>
    }

    return (
      <TextField
        disabled
        value={withEuroOrDash(item.data.price_eur)}
        InputProps={{ classes }}
        error={!!error}
        helperText={error}
      />
    )
  }],

}

const tableStructureForModelPortfolio = [
  PortfolioNameColumn,
  StateCol,
  AmountColumn,
  DateColumn,
  BrokerFeeColumn,
  DiscountColumn,
  ActionsColumn
];

const tableStructureSwitchOut = [
  NameCol,
  SwitchOutAmountQuantityColumn,
  SwitchOutAmountEuroColumn,
  SwitchOutAmountPercentageColumn,
  SelectAllColumn,
  ActionsColumn
];

const tableStructureSwitchOutVirtual = [
  NameCol,
  SwitchOutAmountQuantityColumn,
  SwitchOutAmountEuroColumn,
  SwitchOutAmountPercentageColumn,
  SelectAllColumn,
  DateColumn,
  PriceEurCol,
  ActionsColumn
];

const tableStructureSwitchIn = [
  SwitchInNameCol,
  SwitchInSaldoPlaceholderColumn,
  SwitchInAmountQuantityColumn,
  SwitchInAmountEuroColumn,
  SwitchInAmountPercentageColumn,
  BrokerFeeColumn,
  DiscountColumn,
  ActionsColumn
];

const tableStructureSwitchInVirtual = [
  SwitchInNameCol,
  SwitchInSaldoPlaceholderColumn,
  SwitchInAmountQuantityColumn,
  SwitchInAmountEuroColumn,
  SwitchInAmountPercentageColumn,
  BrokerFeeColumn,
  DiscountColumn,
  PriceEurCol,
  ActionsColumn
]

export const tableStructureForEinzeltitelBuyTrading = [
  NameCol,
  CourseColumn,
  OrderVariationColumn,
  LimitOrderColumn,
  NumberOfSharesColumn,
  OrderAmountColumn,
  OrderValidityDateColumn,
  StockExchangeColumn,
  ActionsColumn
];

export const tableStructureForEinzeltitelSellTrading = [
  NameCol,
  CourseColumn,
  OrderVariationColumn,
  LimitOrderColumn,
  NumberOfSharesColumn,
  OrderAmountColumn,
  SellEverythingColumn,
  OrderValidityDateColumn,
  StockExchangeColumn,
  ActionsColumn
];

export const tableStructureWithEinzeltitel = [
  StatusCol,
  InternalNameCol,
  PriceCol,
  OrderVariationColumn,
  LimitOrderColumn,
  NumberOfSharesColumn,
  OrderAmountColumn,
  OrderValidityDateColumn,
  StockExchangeColumn,
  ShortActionsColumn
];

export const getPortfolioTypeTransactionTypeTableStructure = (withSustainability) => {
  const structure = {
    virtual: {
      [TRANSACTION_TYPE_VALUES.BUY]: [NameCol, StateCol, AmountColumn, DateColumn, BrokerFeeColumn, DiscountColumn, ActionsColumn],
        [TRANSACTION_TYPE_VALUES.SELL]: [NameCol, StateCol, AmountColumn, DateColumn, SelectAllColumn, BrokerFeeColumn, DiscountColumn, ActionsColumn],
        switch_out: tableStructureSwitchOutVirtual,
        switch_in: tableStructureSwitchInVirtual
    },
    virtual_create: {
      [TRANSACTION_TYPE_VALUES.BUY]: [NameCol, StateCol, AmountColumn, DateColumn, BrokerFeeColumn, DiscountColumn, NewMPWeightColumn, ActionsColumn],
    },
    real: {
      [TRANSACTION_TYPE_VALUES.BUY]: _.cloneDeep(tableStructure),
        [TRANSACTION_TYPE_VALUES.SELL]: [NameCol, StateCol, AmountColumn, SelectAllColumn, BrokerFeeColumn, DiscountColumn, ActionsColumn],
        switch_out: _.cloneDeep(tableStructureSwitchOut),
        switch_in: _.cloneDeep(tableStructureSwitchIn)
    },
    model_portfolio: {
      [TRANSACTION_TYPE_VALUES.BUY]: tableStructureForModelPortfolio,
        [TRANSACTION_TYPE_VALUES.SELL]: [PortfolioNameColumn, StateCol, AmountColumn, SelectAllColumn, BrokerFeeColumn, DiscountColumn, ActionsColumn]
    }
  }

  if (withSustainability) {
    const cell = {
      header: [() => SustainabilityCell.header.content(undefined, {sustainabilityTitle: "Nachhaltig-keit "})],
      body: [({item, options}) => SustainabilityCell.body.content(item.data, options)],
      cellClassName: 'sustainability-cell'
    };

    Object.values(structure.real).forEach((tableStructure) => {
      tableStructure.splice(1, 0, cell);
    });
  }

  return structure;
}